import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter } from "@material-ui/core";

// const URL = "/.netlify/functions/sumHours";
// const URL = "https://time.riddla.de/.netlify/functions/sumHours";
const URL =
  process.env.NODE_ENV === "production"
    ? "/.netlify/functions/sumHours"
    : "http://localhost:9999/sumHours";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  table: {},
  control: {
    padding: theme.spacing(2),
  },
  low: {
    color: "red",
  },
  enough: {
    color: "lightseagreen",
  },
});

class GuttersGrid extends React.Component {
  state = {
    spacing: "10",
    weeks: {},
  };

  componentDidMount() {
    fetch(URL)
      .then((res) => res.json())
      .then((json) => this.setState({ weeks: json }));
  }

  handleChange = (key) => (event, value) => {
    this.setState({
      [key]: value,
    });
  };

  render() {
    const { classes } = this.props;
    const { spacing } = this.state;

    return (
      <Grid container className={classes.root} spacing={10}>
        <Grid item xs={12}>
          <Grid
            container
            className={classes.demo}
            justify="center"
            spacing={Number(spacing)}
          >
            <Grid item className={classes.table}>
              <Typography variant="h5" component="h3">
                {/* <pre>{JSON.stringify(this.state.weeks, null, 2)}</pre> */}
                <TableContainer component={Paper}>
                  {Object.entries(this.state.weeks).map(
                    ([weekNumber, weekData]) => (
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Tag</TableCell>
                            <TableCell>Stunden</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.entries(weekData.days).map(
                            (
                              [day, hours] //{" "}
                            ) => (
                              // <pre>{JSON.stringify(hours, null, 2)}</pre>
                              <TableRow key={day}>
                                <TableCell component="th" scope="row">
                                  {day}
                                </TableCell>
                                <TableCell align="right">{hours}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell>Summe</TableCell>
                            <TableCell align="right">
                              <span
                                className={`${
                                  weekData.sum < 35
                                    ? classes.low
                                    : classes.enough
                                }`}
                              >
                                {weekData.sum}
                              </span>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    )
                  )}
                </TableContainer>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

GuttersGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GuttersGrid);
